

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
scroll-behavior: smooth;
}

@font-face {
    font-family: "Alphakind";
    src: url("./assets/font/Alphakind.ttf") format("truetype");
   }

@font-face {
    font-family: "BebasNeue-Regular-bold";
    src: url("./assets/font/BebasNeue-Regular-bold.ttf") format("truetype");
}


body {
    background-color: rgb(255, 255, 255);
    overflow-x: hidden;
    position: relative;

}